import { useEffect, useRef } from 'react';
import { Box, Chip, Fade, List, ListItem, Stack, styled, Typography } from '@mui/material';
import { AIResponse, ChatbotFAQ } from 'src/@types/apiResponseTypes';
import { EditorView } from 'src/components/block-editor';

import LoadingDots from '../../utils/Animations/LoadingDots';
import { SmileMessageIcon } from '../../utils/Icons';
import { ConversationType, isChatbotFAQType, isConversationTypeArray } from '../../utils/types';
import MessageInputArea from '../message-input-area';
import InitialScreenInputArea from '../message-input-area/InitialScreenInputArea';
import NewChatButton from '../NewChatButton';

import CustomReactMarkdown from './CustomReactMarkdown';
import FaqTagRecommender from './FaqTagRecommender';
import InitialChatWindow from './InitialChatWindow';
import Skeleton from './Skeleton';

interface ChatWindowProps {
  isInitialChatPage: boolean;
  isStreaming: boolean;
  aiResponse: AIResponse;
  aiResponseStatus: { isAITyping: boolean; error: string | null };
  chatFaqs: ChatbotFAQ[];
  chatRoom: Array<ConversationType> | ChatbotFAQ;
  currentChatRoomId: string;
  resizableChatWindowWidth: number;
  onAddNewChatRoom: () => void;
  onSendMessageToAI: (response: string) => void;
  onClose: () => void;
}

// 메인 ChatWindow 컴포넌트
function ChatWindow({
  isInitialChatPage,
  isStreaming,
  aiResponse,
  aiResponseStatus,
  chatFaqs,
  chatRoom,
  currentChatRoomId,
  resizableChatWindowWidth,
  onAddNewChatRoom,
  onSendMessageToAI,
  onClose,
}: ChatWindowProps) {
  const { isAITyping, error } = aiResponseStatus;
  const isAiProcessing = isAITyping || isStreaming;
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: isStreaming ? 'auto' : 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatRoom, currentChatRoomId, isAITyping, isStreaming]);

  // 초기 화면 렌더링
  if (isConversationTypeArray(chatRoom) && isInitialChatPage) {
    return (
      <InitialChatWindow
        resizableChatWindowWidth={resizableChatWindowWidth}
        MessageInput={
          <InitialScreenInputArea
            resizableChatWindowWidth={resizableChatWindowWidth}
            isAiProcessing={isAiProcessing}
            onSendMessageToAI={onSendMessageToAI}
          />
        }
        FaqTagRecommender={
          <FaqTagRecommender chatFaqs={chatFaqs} onSendMessageToAI={onSendMessageToAI} />
        }
        onClose={onClose}
      />
    );
  }

  // 대화 목록 렌더링
  if (isConversationTypeArray(chatRoom)) {
    return (
      <Skeleton
        resizableChatWindowWidth={resizableChatWindowWidth}
        bottomComponent={
          <MessageInputArea isAiProcessing={isAiProcessing} onSendMessageToAI={onSendMessageToAI} />
        }
        onClose={onClose}
      >
        <ConversationList
          chatRoom={chatRoom}
          error={error}
          isStreaming={isStreaming}
          aiResponse={aiResponse}
          isAITyping={isAITyping}
          messagesEndRef={messagesEndRef}
        />
      </Skeleton>
    );
  }

  // FAQ 가이드 렌더링
  if (isChatbotFAQType(chatRoom)) {
    return (
      <InnerwaveGuideView
        chatRoom={chatRoom}
        isAiProcessing={isAiProcessing}
        resizableChatWindowWidth={resizableChatWindowWidth}
        onAddNewChatRoom={onAddNewChatRoom}
        onClose={onClose}
      />
    );
  }

  // 기본 화면 렌더링
  return (
    <Skeleton
      resizableChatWindowWidth={resizableChatWindowWidth}
      bottomComponent={
        <MessageInputArea isAiProcessing={isAiProcessing} onSendMessageToAI={onSendMessageToAI} />
      }
    >
      <InitialChatWindow resizableChatWindowWidth={resizableChatWindowWidth} onClose={onClose} />
    </Skeleton>
  );
}

// 메시지 아이템 컴포넌트
const MessageItem = ({ conv }: { conv: ConversationType }) => (
  <div>
    {conv.userMessage.user_input && (
      <Box sx={{ textAlign: 'right' }}>
        <Stack
          sx={{ display: 'inline-flex', background: '#1565C01A', p: 2, borderRadius: 3, m: 2 }}
        >
          <Typography>{conv.userMessage.user_input}</Typography>
          {/* {conv.userMessage.reg_dt && (
            <Typography variant={'caption'} sx={{ color: '#666' }}>
              {moment(conv.userMessage.reg_dt).fromNow()}
            </Typography>
          )} */}
        </Stack>
      </Box>
    )}
    {conv.aiResponse.response && (
      <Stack direction={'row'} sx={{ p: 2 }}>
        <SmileMessageIcon />
        <StyledListItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            borderBottom: 0,
            pt: 0,
            pl: 1,
          }}
        >
          {conv.aiResponse.is_faq ? (
            <EditorView key={conv.aiResponse.chat_id} content={conv.aiResponse.response} />
          ) : (
            <CustomReactMarkdown text={conv.aiResponse.response} />
          )}
        </StyledListItem>
      </Stack>
    )}
  </div>
);

// FAQ 가이드 컴포넌트
const InnerwaveGuideView = ({
  chatRoom,
  isAiProcessing,
  resizableChatWindowWidth,
  onAddNewChatRoom,
  onClose,
}: {
  chatRoom: ChatbotFAQ;
  isAiProcessing: boolean;
  resizableChatWindowWidth: number;
  onAddNewChatRoom: () => void;
  onClose: () => void;
}) => {
  const { tag, answer } = chatRoom;

  return (
    <Skeleton
      resizableChatWindowWidth={resizableChatWindowWidth}
      bottomComponent={
        <Box sx={{ padding: '16px 12px' }}>
          <NewChatButton disabled={isAiProcessing} onClick={onAddNewChatRoom} />
        </Box>
      }
      onClose={onClose}
    >
      <ListItem sx={{ padding: '8px 16px' }}>
        {/* key값을 주어 강제로 리렌더링을 시켜서 업데이트된 정보를 화면에 보이게 함. */}
        <EditorView key={answer} content={answer} />
      </ListItem>
      <ListItem sx={{ padding: '8px 16px', width: '100%', display: 'flex', flexWrap: 'wrap' }}>
        {tag?.split(',').map((t) => (
          <Chip
            key={t}
            sx={{ margin: '3px' }}
            size="medium"
            label={`# ${t}`}
            onClick={() => {
              console.log('hihi');
            }}
          />
        ))}
      </ListItem>
    </Skeleton>
  );
};

// 대화 목록 컴포넌트
const ConversationList = ({
  chatRoom,
  error,
  isStreaming,
  aiResponse,
  isAITyping,
  messagesEndRef,
}: {
  chatRoom: Array<ConversationType>;
  error: string | null;
  isStreaming: boolean;
  aiResponse: AIResponse;
  isAITyping: boolean;
  messagesEndRef: React.RefObject<HTMLDivElement>;
}) => (
  <List>
    {error && <ListItem>{error}</ListItem>}
    {chatRoom.map((conv, index) => (
      <MessageItem key={index} conv={conv} />
    ))}
    {!error && isStreaming && (
      <StyledListItem
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          borderBottom: 0,
          pt: 0,
          pl: 1,
        }}
      >
        {aiResponse.is_faq ? (
          <EditorView key={aiResponse.response} content={aiResponse.response} />
        ) : (
          <Stack flex={1} direction={'row'} spacing={1} sx={{ p: 2, pl: 1 }}>
            <SmileMessageIcon />
            <Stack sx={{ width: '100%' }}>
              <CustomReactMarkdown text={aiResponse.response} />
            </Stack>
          </Stack>
        )}
      </StyledListItem>
    )}
    {isAITyping && (
      <StyledListItem sx={{ border: 'none' }}>
        <Fade in timeout={1000}>
          <Stack direction={'row'} sx={{ p: 1, pl: 0 }}>
            <SmileMessageIcon />
            <Typography sx={{ pl: 1 }}>알맞은 답변을 생각하고 있어요</Typography>
          </Stack>
        </Fade>
        <LoadingDots />
      </StyledListItem>
    )}
    {/* 스크롤을 밑으로 가게 해주는 엘리먼트 */}
    <div ref={messagesEndRef} />
  </List>
);

const StyledListItem = styled(ListItem)(() => ({
  padding: '8px 16px',
  borderBottom: '1px solid #B9B9B9',
}));

export default ChatWindow;
