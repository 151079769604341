import { useCallback, useState } from 'react';
import { ChatbotHistoryResponse, ChatListRequest } from 'src/@types/apiResponseTypes';
import { chatbotApi } from 'src/api';

import { ConversationType } from '../utils/types';

function useChatResponse() {
  const [chatbotHistoryResponse, setChatbotHistoryResponse] = useState<
    ChatbotHistoryResponse | undefined
  >(undefined);
  const [conversations, setConversations] = useState<ConversationType[] | undefined>(undefined);

  const getChatList = useCallback(async (chatListRequest: ChatListRequest) => {
    try {
      const data = await chatbotApi.getChatList(chatListRequest);
      if (data) {
        return data.chat_list;
      }
      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }, []);

  const findChatHistory = async ({ chat_id }: { chat_id: string }) => {
    try {
      const data = await chatbotApi.findChatHistory({ chat_id });
      if (data) {
        const newConversation: ConversationType[] = data.history.map(
          ({ ord, user_id, user_input, response, reg_dt, is_faq }) => ({
            aiResponse: { chat_id, user_id, response, ord, reg_dt, is_faq },
            userMessage: { user_id, user_input, reg_dt },
          }),
        );
        setConversations(newConversation);
        setChatbotHistoryResponse(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteChat = async ({ chat_id }: { chat_id: string }) => {
    try {
      const data = await chatbotApi.deleteChat({ chat_id });
      if (data) {
        setChatbotHistoryResponse(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAllChats = async (user_id: number) => {
    try {
      const data = await chatbotApi.deleteAllChat(user_id);
      if (data) {
        setChatbotHistoryResponse(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getChatList,
    history: { chatbotHistoryResponse, setChatbotHistoryResponse, findChatHistory },
    conv: { conversations, setConversations },
    onDeleteChatRoom: {
      deleteChat,
      deleteAllChats,
    },
  };
}

export default useChatResponse;
