import { Dispatch, SetStateAction } from 'react';
import {
  AIResponse,
  ChatbotFAQ,
  ChatbotHistoryResponse,
  ChatList,
} from 'src/@types/apiResponseTypes';

export type TSetState<T> = Dispatch<SetStateAction<T>>;

export const AI_RESPONSE_DEFAULT_VALUE: AIResponse = {
  user_id: 0,
  chat_id: '',
  chat_title: '',
  response: '',
  complete: false,
  ord: 0,
  reg_dt: '',
  is_faq: false,
};

export interface MessageInfo {
  id: string;
  // 메시지
  response: string;
  // 사용자인지 AI 봇인지를 구별
  isUser: boolean;
}

// MessageInfo 타입인지 아닌지 타입 체크
export function isMessageInfoType(obj: any): obj is MessageInfo {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    typeof obj.chat_id === 'number' &&
    typeof obj.chat_title === 'string' &&
    typeof obj.response === 'string' &&
    typeof obj.isUser === 'boolean'
  );
}

export function isChatbotFAQType(object: any): object is ChatbotFAQ {
  return (
    typeof object === 'object' &&
    object !== null &&
    'faq_id' in object &&
    'question' in object &&
    'answer' in object &&
    'tag' in object
  );
}

export function isChatListType(object: any): object is ChatList {
  return (
    typeof object === 'object' &&
    object !== null &&
    'chat_id' in object &&
    'chat_title' in object &&
    'reg_dt' in object
  );
}

export function isChatListTypeArray(object: any): object is ChatList[] {
  return (
    Array.isArray(object) &&
    object.every(
      (item) =>
        typeof item === 'object' &&
        item !== null &&
        'chat_id' in item &&
        'chat_title' in item &&
        'reg_dt' in item,
    )
  );
}

export function isChatbotResponseType(object: any): object is ChatbotHistoryResponse {
  return (
    typeof object === 'object' &&
    object !== null &&
    typeof object.chat_id === 'string' &&
    Array.isArray(object.history) &&
    object.history.every(
      (item: any) =>
        typeof item === 'object' &&
        item !== null &&
        typeof item.ord === 'number' &&
        typeof item.user_id === 'number' &&
        typeof item.user_input === 'string' &&
        typeof item.response === 'string' &&
        typeof item.reg_dt === 'string',
    )
  );
}

export interface CurrentChatRoomIdState {
  currentChatRoomId: string;
  onSetCurrentRoomId: {
    onSetCurrentChatRoomId: (chat_id: string) => Promise<void>;
    onSetCurrentFAQRoomId: (faq_id: number) => Promise<void>;
  };
}

export interface BaseResponseType {
  // 유저 id
  user_id: number;
  // 단일 메시지의 id
  chat_id: string;
  // 챗봇 AI 메시지
  response: string;
  ord: number;
  reg_dt: string;
  is_faq: boolean;
}

export interface ConversationType {
  aiResponse: BaseResponseType;
  userMessage: { user_id: number; user_input: string; reg_dt: string };
}

export function isConversationType(object: any): object is ConversationType {
  return (
    typeof object === 'object' &&
    object !== null &&
    typeof object.aiResponse === 'object' &&
    object.aiResponse !== null &&
    typeof object.aiResponse.chat_id === 'string' &&
    typeof object.aiResponse.ord === 'number' &&
    typeof object.aiResponse.reg_dt === 'string' &&
    typeof object.aiResponse.response === 'string' &&
    typeof object.aiResponse.user_id === 'number' &&
    typeof object.userMessage === 'object' &&
    object.userMessage !== null &&
    typeof object.userMessage.user_id === 'number' &&
    typeof object.userMessage.user_input === 'string'
  );
}

export function isConversationTypeArray(object: any): object is ConversationType[] {
  return Array.isArray(object) && object.every((item) => isConversationType(item));
}

export interface OnDeleteChatRoom {
  onDeleteSingleChatRoom: (id: string) => void;
  onDeleteAllChatRooms: () => void;
}
