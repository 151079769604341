import React, { createContext, useContext } from 'react';

import { ChatActions, ChatState, useChatState } from '../hooks/useChatState';
import useSendChatMessage from '../hooks/useSendChatMessage';
import { AI_RESPONSE_DEFAULT_VALUE } from '../utils/types';

export type ChatStateContextType = ChatState;

export type ChatActionsContextType = ChatActions & {
  sendMessageToAI: (user_input: string, user_sid?: number) => Promise<void>;
};

const ChatStateContext = createContext<ChatStateContextType | null>(null);
const ChatActionsContext = createContext<ChatActionsContextType | null>(null);

export function ChatProvider({ children }: React.PropsWithChildren) {
  const {
    // State
    aiResponse,
    aiResponseStatus,
    conversations,
    currentChatRoomId,
    sidebarRef,
    isStreaming,
    isChatWindowOpened,
    isInitialChatPage,
    // Actions
    setCurrentChatRoomId,
    setAiResponse,
    setAiResponseStatus,
    setIsStreaming,
    setIsChatWindowOpened,
    setConversations,
    getChatList,
    findChatHistory,
    sanitizeChatRoomState,
    setIsInitialChatPage,
    moveToInitialChatPage,
  } = useChatState();

  const { sendMessageToAI } = useSendChatMessage({
    currentChatRoomId,
    setCurrentChatRoomId,
    setConversations,
    setAiResponse,
    setAiResponseStatus,
    setIsStreaming,
    getChatList,
    setIsInitialChatPage,
    sidebarRef,
    AI_RESPONSE_DEFAULT_VALUE,
  });

  const actions: ChatActionsContextType = {
    setAiResponse,
    setAiResponseStatus,
    setCurrentChatRoomId,
    setConversations,
    setIsStreaming,
    setIsChatWindowOpened,
    findChatHistory,
    getChatList,
    sendMessageToAI,
    sanitizeChatRoomState,
    setIsInitialChatPage,
    moveToInitialChatPage,
  };

  const state: ChatState = {
    aiResponse,
    conversations,
    currentChatRoomId,
    sidebarRef,
    aiResponseStatus,
    isStreaming,
    isChatWindowOpened,
    isInitialChatPage,
  };

  return (
    <ChatStateContext.Provider value={state}>
      <ChatActionsContext.Provider value={actions}>{children}</ChatActionsContext.Provider>
    </ChatStateContext.Provider>
  );
}

// Custom hooks for using the context
export function useChatStateContext() {
  const context = useContext(ChatStateContext);
  if (!context) {
    throw new Error('useChatStateContext must be used within ChatProvider');
  }
  return context;
}

export function useChatActionsContext() {
  const context = useContext(ChatActionsContext);
  if (!context) {
    throw new Error('useChatActionsContext must be used within ChatProvider');
  }
  return context;
}

export function useChat() {
  return {
    ...useChatStateContext(),
    ...useChatActionsContext(),
  };
}
