import { forwardRef, useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, ListItem, Typography } from '@mui/material';
import { ChatbotFAQ, ChatList } from 'src/@types/apiResponseTypes';

import { CurrentChatRoomIdState, isChatbotFAQType, isChatListType } from '../../utils/types';

interface ChatListItemProps {
  chatRoom: ChatList | ChatbotFAQ;
  currentChatRoomIdState: CurrentChatRoomIdState;
  onCloseDrawer?: () => void;
  onDeleteSingleChatRoom?: (chat_id: string) => void;
}

const ChatListItem = forwardRef<HTMLLIElement, ChatListItemProps>(
  (
    { chatRoom, currentChatRoomIdState, onCloseDrawer, onDeleteSingleChatRoom },
    lastChatElementRef,
  ) => {
    const [removeChatYn, setRemoveChatYn] = useState<boolean>(false);
    const backgroundColor = '#6a9feb';
    const { currentChatRoomId, onSetCurrentRoomId } = currentChatRoomIdState;
    const { onSetCurrentChatRoomId, onSetCurrentFAQRoomId } = onSetCurrentRoomId;

    const setRemoveChatNo = () => {
      setRemoveChatYn(false);
    };

    // 다른 채팅방 클릭 시 채팅방 삭제 버튼 상태를 원래 상태로 초기화
    useEffect(() => {
      setRemoveChatNo();
    }, [currentChatRoomId]);

    if (isChatListType(chatRoom)) {
      const { chat_id, chat_title } = chatRoom;

      const isCurrentChatRoom = currentChatRoomId === chat_id;
      const newChat = chat_title.length === 0;

      return newChat ? null : (
        <ListItem
          key={chat_id}
          ref={lastChatElementRef}
          onClick={(event) => {
            event.stopPropagation();
            onSetCurrentChatRoomId(chat_id);
            onCloseDrawer && onCloseDrawer();
          }}
          sx={{
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: isCurrentChatRoom ? backgroundColor : '',
            borderRadius: '4px',
            padding: '4px 4px 4px 0',
            ':hover': {
              backgroundColor: backgroundColor,
            },
            transition: 'all 0.2s',
          }}
        >
          <Typography variant="subtitle2" sx={{ color: '#FFF', fontWeight: 400, px: 1 }}>
            {chat_title}
          </Typography>
          {/* Innerwave Guide 섹션이면 휴지통 아이콘 가리기 */}
          <IconButton size="small" sx={{ padding: 0 }}>
            {removeChatYn ? (
              <>
                <CheckIcon
                  sx={{ fontSize: 18, color: '#FFF8' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeleteSingleChatRoom && onDeleteSingleChatRoom(chat_id);
                  }}
                />
                <CloseIcon
                  sx={{ fontSize: 18, color: '#FFF8' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setRemoveChatYn(false);
                  }}
                />
              </>
            ) : (
              isCurrentChatRoom && (
                <DeleteOutlineIcon
                  sx={{ fontSize: 18, color: '#FFF8' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setRemoveChatYn(true);
                  }}
                />
              )
            )}
          </IconButton>
        </ListItem>
      );
    } else if (isChatbotFAQType(chatRoom)) {
      const { faq_id, question } = chatRoom;
      const stringifyFaqId = faq_id.toString();
      return (
        <ListItem
          key={faq_id}
          onClick={() => {
            onSetCurrentFAQRoomId(faq_id);
            onCloseDrawer && onCloseDrawer();
          }}
          sx={{
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: currentChatRoomId === stringifyFaqId ? backgroundColor : '',
            borderRadius: '4px',
            padding: '4px 8px 4px 8px',
            ':hover': {
              backgroundColor: backgroundColor,
            },
            transition: 'all 0.2s',
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
            {question}
          </Typography>
        </ListItem>
      );
    } else {
      return null;
    }
  },
);

export default ChatListItem;
