import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { AddChatbotFAQPayload, ChatbotFAQ } from 'src/@types/apiResponseTypes';
import { chatbotApi } from 'src/api';

function useFAQResponse() {
  const [faq, setFaq] = useState<ChatbotFAQ | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const getFAQs = async ({
    page = 1,
    size = 25,
    keyword,
  }: {
    keyword?: string;
    page: number;
    size: number;
  }) => {
    try {
      const result = await chatbotApi.getFAQs({ page, size, keyword });
      if (result) {
        return result;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTop5ViewFAQs = async () => {
    try {
      const faqs = await chatbotApi.getTop5ViewFAQs();
      if (faqs) {
        return faqs;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const findFAQ = async (faq_id: number) => {
    try {
      const data = await chatbotApi.findFAQ(faq_id);
      if (data) {
        setFaq(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addFAQ = async (requestBody: AddChatbotFAQPayload) => {
    try {
      const response = await chatbotApi.addFAQ(requestBody);

      if (response) {
        enqueueSnackbar('FAQ가 등록되었습니다.', { variant: 'success' });
      } else {
        enqueueSnackbar('FAQ가 등록에 실패하였습니다.', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { getFAQs, getTop5ViewFAQs, addFAQ, faq, findFAQ, setFaq };
}

export default useFAQResponse;
