import { useEffect, useState } from 'react';

const NUMBER_OF_DOTS = 5;
const SPEED_OF_DOTS = 200; // millisecond

const LoadingDots = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length >= NUMBER_OF_DOTS ? '' : prev + '.'));
    }, SPEED_OF_DOTS);

    return () => clearInterval(interval);
  }, []);

  return <span className="inline-block min-w-[24px]">{dots}</span>;
};

export default LoadingDots;
