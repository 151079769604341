import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { Box, Button, Chip, IconButton, styled } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import {
  ChatbotFAQ,
  ChatbotFeedback,
  IAttachment,
  IClient,
  IDepartment,
  IEmployee,
  IEquipment,
  IPersonnelHistory,
  IProject,
  IReport,
  IReportTemplate,
  IServer,
  IUserGroup,
  IVm,
  NasList,
  RDBList,
  VectorDB,
  WebCrawlingList,
} from 'src/@types/apiResponseTypes';
import { reportApi } from 'src/api';
import Iconify from 'src/components/iconify';
import { useStores } from 'src/models';
import { fDate, fDateTime } from 'src/utils/formatTime';
/**
 * ## useGetGridCol
 * - Grid Col을 가져오는 Hook
 */

export interface IEmployeeManagementGridRow extends IEmployee {
  id: number;
}

export interface IDeptManagementGridRow extends IDepartment {
  id: number;
}

export interface IPersonnelManagementGridRow extends IPersonnelHistory {
  id: number;
}

export interface IGroupManagementGridRow extends IUserGroup {
  id: number;
}

export interface IClientManagementGridRow extends IClient {
  id: number;
}

export interface IProjectManagementGridRow extends IProject {
  id: number;
}

export interface IReportGridRow extends IReport {
  id: number;
}

export interface IReportTemplateGridRow extends IReportTemplate {
  id: number;
}

export interface IReportAttachmentGridRow extends IAttachment {
  id: number;
}
export interface IEquipmentManagementGridRow extends IEquipment {
  id: number;
}

export interface IVmManagementGridRow extends IVm {
  id: number;
}
export interface IServerManagementGridRow extends IServer {
  id: number;
}

export interface ChatbotFAQGridRow extends ChatbotFAQ {
  id: number;
}

export interface ChatbotFeedbackGridRow extends ChatbotFeedback {
  id: number;
}

export interface VectorDBGridRow extends VectorDB {
  id: string;
}

export interface NasGridRow extends NasList {
  id: string;
}

export interface RDBGridRow extends RDBList {
  id: string;
}

export interface WebCrawlingGridRow extends WebCrawlingList {
  id: string;
}

function useGetGridCol() {
  const { alertStore } = useStores();
  // 직원 관리 그리드 컬럼 정의
  function getEmployeeManagementGridCol(
    departments?: IDepartment[],
  ): GridColDef<IEmployeeManagementGridRow>[] {
    return [
      { field: 'id' },
      {
        field: 'empNm',
        headerName: '이름',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'user.loginId',
        headerName: '아이디',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        flex: 1,
        valueGetter: (value, row) => row.user?.loginId || '',
      },
      {
        field: 'user.userGroupSidList',
        headerName: '메뉴권한',
        headerAlign: 'center',
        align: 'center',
        width: 200,
        renderCell: (param) => {
          return (
            <ChipContainer>
              {param.row.user?.userGroupList?.map((group) => (
                <StyledChip key={group.userGroupSid} label={group.userGroupNm} />
              ))}
            </ChipContainer>
          );
        },
      },
      {
        field: 'empPosCd',
        headerName: '직급',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        type: 'string',
        valueGetter: (value, row) => row.empPosCd?.value || '',
      },
      {
        field: 'empJobrespCd',
        headerName: '직책',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        type: 'string',
        valueGetter: (value, row) => row.empJobrespCd?.value || '',
      },
      {
        field: 'deptSid',
        headerName: '부서',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        type: 'string',
        valueGetter: (value, row) => {
          if (departments && departments.length > 0) {
            const dept = departments.find((dept) => dept.deptSid === row.deptSid);
            return dept?.deptNm || '';
          }
          return '';
        },
      },
      {
        field: 'phone',
        headerName: '연락처',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'hireDay',
        headerName: '입사일',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        valueGetter: (value, row) => fDate(row.hireDay),
      },
    ];
  }

  // 부서 관리 그리드 컬럼 정의
  function getDeptManagementGridCol(): GridColDef<IDeptManagementGridRow>[] {
    return [
      { field: 'id' },
      {
        field: 'pnm',
        headerName: '상위부서',
        headerAlign: 'left',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'deptNm',
        headerName: '이름',
        headerAlign: 'left',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'ordr',
        headerName: '순서',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'regDt',
        headerName: '등록일시',
        headerAlign: 'left',
        flex: 1,
        minWidth: 150,
        valueGetter: (value, row) => fDateTime(row.regDt),
      },
      {
        field: 'updDt',
        headerName: '수정일시',
        headerAlign: 'left',
        flex: 1,
        minWidth: 150,
        valueGetter: (value, row) => fDateTime(row.updDt),
      },
    ];
  }

  // 고객사 관리 그리드 컬럼 정의
  function getClientManagementGridCol(): GridColDef<IClientManagementGridRow>[] {
    return [
      {
        field: 'company',
        headerName: '고객사',
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
      },
      {
        field: 'divisionCd',
        headerName: '구분',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => row.divisionCd?.value ?? '',
        minWidth: 120,
      },
      {
        field: 'gradeCd',
        headerName: '등급',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => row.gradeCd?.value ?? '',
        minWidth: 100,
      },
      {
        field: 'empCnt',
        headerName: '사원수',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
      },
      {
        field: 'telNo',
        headerName: '유선번호',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
      },
      {
        field: 'addr',
        headerName: '주소',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
      },
      {
        field: 'regUser',
        headerName: '등록자',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => row.regUser.userNm || '',
        minWidth: 150,
      },
      {
        field: 'compMemo',
        headerName: '메모',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        valueGetter: (value, row) => row.compMemo || '',
      },
    ];
  }

  // 프로젝트 관리 그리드 컬럼 정의
  function getProjectManagementGridCol(
    departments?: IDepartment[],
    onClickWbs?: (param: GridRenderCellParams) => void,
  ): GridColDef<IProjectManagementGridRow>[] {
    return [
      {
        field: 'bo',
        headerName: 'BO',
        flex: 60,
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
      },
      {
        field: 'pm',
        headerName: 'PM',
        flex: 60,
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        renderCell: (param) => {
          let names = '';
          param.row.pmList?.forEach((emp) => {
            names += emp.empNm + ',';
          });
          return names.substring(0, names.length - 1);
        },
      },
      {
        field: 'customerCmpy.company',
        headerName: '고객사',
        flex: 60,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        valueGetter: (value, row) => row.customerCmpy?.company || '',
      },
      {
        field: 'projectNm',
        headerName: '프로젝트명',
        flex: 300,
        headerAlign: 'center',
        align: 'left',
        minWidth: 200,
      },
      {
        field: 'cntrctNm',
        headerName: '계약명',
        flex: 300,
        headerAlign: 'center',
        align: 'left',
        minWidth: 200,
      },
      {
        field: 'deprtCd1',
        headerName: '담당본부',
        flex: 100,
        headerAlign: 'center',
        align: 'left',
        minWidth: 150,
        valueGetter: (value, row) => {
          if (departments && departments.length > 0) {
            const dept = departments.find((dept) => dept.deptSid === row.deprtCd);
            if (dept?.deptPsid === 3001) {
              return dept?.deptNm;
            } else {
              return departments.find((r) => r.deptSid === dept?.deptPsid)?.deptNm || '';
            }
          }
          return '';
        },
      },
      {
        field: 'projectMngTypeCd',
        headerName: '구분',
        flex: 100,
        headerAlign: 'center',
        align: 'left',
        minWidth: 150,
        valueGetter: (value, row) => row.projectMngTypeCd?.value || '',
      },
      {
        field: 'descr',
        headerName: '비고',
        flex: 300,
        headerAlign: 'center',
        align: 'left',
        minWidth: 200,
      },
      {
        field: 'deprtCd',
        headerName: '담당팀',
        flex: 100,
        headerAlign: 'center',
        align: 'left',
        minWidth: 150,
        valueGetter: (value, row) => {
          if (departments && departments.length > 0) {
            const dept = departments.find((dept) => dept.deptSid === row.deprtCd);
            return dept?.deptNm || '';
          }
          return '';
        },
      },
      {
        field: 'startDay',
        headerName: '시작일',
        flex: 60,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        valueGetter: (value, row) => fDateTime(row.startDay, 'yyyy-MM-dd'),
      },
      {
        field: 'endDay',
        headerName: '종료일',
        flex: 60,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        valueGetter: (value, row) => fDateTime(row.endDay, 'yyyy-MM-dd'),
      },
      {
        field: 'wbs',
        headerName: 'WBS',
        flex: 60,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        minWidth: 100,
        renderCell: (param) => (
          <Button
            size={'small'}
            variant={'outlined'}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              onClickWbs && onClickWbs(param);
            }}
          >
            WBS
          </Button>
        ),
      },
      {
        field: 'members',
        headerName: '참여자',
        flex: 100,
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
        renderCell: (param) => (
          <ChipContainer>
            {param.row.mngList?.map((emp) => {
              return <StyledChip key={emp.userSid} label={emp.empNm} />;
            })}
          </ChipContainer>
        ),
      },
    ];
  }

  // 권한 그룹 관리 그리드 컬럼 정의
  function getGroupManagementGridCol(): GridColDef<IGroupManagementGridRow>[] {
    return [
      { field: 'id' },
      {
        field: 'userGroupNm',
        headerName: '그룹명',
        headerAlign: 'left',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'dscrpt',
        headerName: '설명',
        headerAlign: 'left',
        flex: 2,
        minWidth: 200,
      },
      {
        field: 'updDt',
        headerName: '수정 일시',
        headerAlign: 'left',
        minWidth: 150,
        valueGetter: (value, row) => fDateTime(row.updDt),
      },
    ];
  }

  // 보고서 템플릿 그리드 컬럼 정의
  function getReportTmpltGridCol(): GridColDef<IReportTemplateGridRow>[] {
    return [
      { field: 'id', headerName: 'ID', headerAlign: 'right', align: 'right', flex: 1 },
      {
        field: 'title',
        headerName: '템플릿명',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'descr',
        headerName: '설명',
        headerAlign: 'left',
        align: 'left',
        flex: 2,
        minWidth: 200,
      },
      {
        field: 'regUser',
        headerName: '작성자',
        headerAlign: 'left',
        align: 'left',
        valueGetter: (value, row) => row?.regUser?.userNm,
        minWidth: 150,
      },
      {
        field: 'regDt',
        headerName: '등록일시',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => fDateTime(row.regDt),
        minWidth: 150,
      },
      {
        field: 'updDt',
        headerName: '수정일시',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => fDateTime(row.updDt),
        minWidth: 150,
      },
      {
        field: 'useYn',
        headerName: '사용여부',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => (row?.useYn === true ? '⭕️' : '❌'),
        minWidth: 150,
      },
    ];
  }

  const handleDownloadReportFilesToZip = async (
    e: React.MouseEvent<HTMLElement>,
    reportSid: number,
    title: string,
  ) => {
    e.stopPropagation();
    await reportApi.downloadReportFilesToZip(reportSid, title);
  };

  // 보고서 그리드 컬럼 정의
  function getReportGridCol(): GridColDef<IReportGridRow>[] {
    return [
      {
        field: 'title',
        headerName: '제목',
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        minWidth: 200,
      },
      {
        field: 'regUser',
        headerName: '작성자',
        flex: 0.7,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        valueGetter: (value, row) => row?.regUser?.userNm,
      },
      {
        field: 'meeting',
        headerName: '참여자',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        renderCell: (param) => (
          <ChipContainer>
            {param.row.meeting?.members?.map((emp) => {
              return <StyledChip key={emp.userSid} label={emp.empNm} />;
            })}
          </ChipContainer>
        ),
      },
      {
        field: 'extrnList',
        headerName: '외부참여자',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        renderCell: (param) => (
          <ChipContainer>
            {param.row.meeting?.extrnList?.map((extrn, index) => {
              return <StyledChip key={`extrn-${index}`} label={extrn.extrnName} />;
            })}
          </ChipContainer>
        ),
      },
      {
        field: 'tagList',
        headerName: '구분',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        renderCell: (param) => (
          <ChipContainer>
            {param.row.tagList?.map((tag, index) => {
              return <StyledChip key={`tag-${index}`} label={tag.tagContent} />;
            })}
          </ChipContainer>
        ),
      },
      {
        field: 'regDt',
        headerName: '등록일시',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        valueGetter: (value, row) => fDateTime(row.regDt),
      },
      {
        field: '',
        type: 'actions',
        headerName: '첨부파일',
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row, value }) => {
          const hasFile = row.fileCnt > 0;
          return hasFile ? (
            <IconButton
              color="primary"
              onClick={(e) => handleDownloadReportFilesToZip(e, row.reportSid, row.title)}
            >
              <DownloadForOfflineOutlinedIcon />
            </IconButton>
          ) : (
            <></>
          );
        },
      },
    ];
  }

  // 업무 보고서 그리드 컬럼 정의
  function getWorkReportGridCol(): GridColDef<IReportGridRow>[] {
    return [
      {
        field: 'title',
        headerName: '제목',
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        minWidth: 200,
      },
      {
        field: 'regUser',
        headerName: '작성자',
        flex: 0.7,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        valueGetter: (value, row) => row?.regUser?.userNm,
      },
      {
        field: 'regDt',
        headerName: '등록일시',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        valueGetter: (value, row) => fDateTime(row.regDt),
      },
    ];
  }

  // 보고서 첨부파일 다운로드
  const handleDownloadFile = async (e: React.MouseEvent<HTMLElement>, attachment: IAttachment) => {
    e.stopPropagation();
    await reportApi.downloadFile(attachment);
  };

  const getReportAttachmentGridCol = (
    onDeleteFile: (attachment: IAttachment) => void,
  ): GridColDef<IReportAttachmentGridRow>[] => {
    // 보고서 첨부파일 삭제
    const handleDeleteFile = async (e: React.MouseEvent<HTMLElement>, attachment: IAttachment) => {
      e.stopPropagation();
      onDeleteFile(attachment);
    };

    return [
      {
        field: 'docuTypeCd',
        headerName: '문서유형',
        flex: 0.7,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        valueGetter: (value, row) => row?.docuTypeCd?.value,
      },
      {
        field: 'title',
        headerName: '보고서 제목',
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        minWidth: 200,
      },
      {
        field: 'fileNm',
        headerName: '파일명',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
      },
      {
        field: 'regUser',
        headerName: '등록자',
        headerAlign: 'center',
        align: 'center',
        width: 150,
        valueGetter: (value, row) => row.regUser?.userNm || '',
      },
      {
        field: 'regDt',
        headerName: '등록일시',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        valueGetter: (value, row) => fDateTime(row.regDt),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => {
          return [
            <GridActionsCellItem
              icon={<DownloadForOfflineOutlinedIcon />}
              onClick={(e) => handleDownloadFile(e, row)}
              label="다운로드"
              showInMenu
            />,
            <GridActionsCellItem
              icon={<DeleteOutlineIcon />}
              onClick={(e) => {
                // 첨부파일 삭제 confirm 시 처리 로직
                alertStore.setOnConfirm(() => handleDeleteFile(e, row));
                // 첨부파일 삭제 요청 시 alert 띄우기
                alertStore.setProps({
                  open: true,
                  category: 'error',
                  title: '첨부파일 삭제',
                  content: '첨부파일을 삭제하시겠습니까?',
                  hasCancelButton: true,
                });
              }}
              label="삭제"
              showInMenu
            />,
          ];
        },
      },
    ];
  };

  // 자리 배치도 직원 선택 그리드 컬럼 정의
  function getOrganizationLayoutEmployeeGridCol(): GridColDef<IEmployeeManagementGridRow>[] {
    return [
      { field: 'id' },
      {
        field: 'empNm',
        headerName: '이름',
        headerAlign: 'left',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'user.loginId',
        headerName: '아이디',
        headerAlign: 'left',
        flex: 1,
        minWidth: 150,
        valueGetter: (value, row) => row.user?.loginId || '',
      },
      {
        field: 'empPosCd',
        headerName: '직급',
        headerAlign: 'left',
        flex: 1,
        minWidth: 150,
        valueGetter: (value, row) => {
          return row.empPosCd?.value || '';
        },
      },
      {
        field: 'phone',
        headerName: '연락처',
        headerAlign: 'left',
        flex: 1,
        minWidth: 200,
      },
    ];
  }

  // 자리 배치도 직원 선택 그리드 컬럼 정의
  // 장비ID	장비종류	제조사	제품명(모델명)	장비 S/N	구입월	내용연한	장비구매구분	사용상태	사용자	사용시작월	비고
  function getEquipmentManagementGridCol(
    imgClickHandler: any,
  ): GridColDef<IEquipmentManagementGridRow>[] {
    return [
      { field: 'id' },
      {
        field: 'inventoryId',
        headerName: '장비ID',
        headerAlign: 'center',
        maxWidth: 100,
        align: 'center',
      },
      {
        field: 'invenTypeCd',
        headerName: '장비종류',
        headerAlign: 'center',
        flex: 1,
        maxWidth: 90,
        align: 'center',
        valueGetter: (value, row) => row.invenTypeCd?.value,
      },
      {
        field: 'manufacturerCd',
        headerName: '제조사',
        headerAlign: 'center',
        flex: 1,
        maxWidth: 90,
        align: 'center',
        valueGetter: (value, row) => row.manufacturerCd?.value,
      },
      {
        field: 'modelName',
        headerName: '제품명(모델명)',
        headerAlign: 'center',
        flex: 1,
        minWidth: 250,
      },
      {
        field: 'modelSn',
        headerName: '장비 S/N',
        headerAlign: 'center',
        minWidth: 200,
        align: 'center',
      },
      {
        field: 'img',
        headerName: '사진',
        headerAlign: 'center',
        maxWidth: 60,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return params.row.equipmentFiles && params.row.equipmentFiles.length > 0 ? (
            <IconButton
              sx={{ mt: 1 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const slides: any[] = [];
                params.row.equipmentFiles.forEach((e: any) => {
                  slides.push({
                    src: 'https://works-api.surfinn.kr/common/upload' + e.filePath,
                  });
                });
                console.log('🚀 ~ useGetGridCol ~ slides:', slides);
                imgClickHandler(slides);
              }}
            >
              <Iconify icon="ph:image" />
            </IconButton>
          ) : (
            <></>
          );
        },
      },
      {
        field: 'purchaseDt',
        headerName: '구입월',
        headerAlign: 'center',
        align: 'center',
        maxWidth: 100,
        valueGetter: (value, row) =>
          row.purchaseDt ? dayjs(row.purchaseDt).format('YYYY/MM') : '-',
      },
      {
        field: 'warrantyDt',
        headerName: '내용연한',
        headerAlign: 'center',
        align: 'center',
        maxWidth: 100,
        valueGetter: (value, row) =>
          row.warrantyDt ? dayjs(row.warrantyDt).format('YYYY/MM') : '-',
      },
      {
        field: 'warranty',
        headerName: '남은기간',
        headerAlign: 'center',
        align: 'center',
        maxWidth: 100,
        valueGetter: (value, row) =>
          row.warrantyDt && row.purchaseDt
            ? Math.floor(dayjs(row.warrantyDt).diff(dayjs(), 'month', true)) + '개월'
            : '-',
      },
      {
        field: 'purchaseTypeCd',
        headerName: '장비구매구분',
        headerAlign: 'center',
        align: 'center',
        maxWidth: 100,
        valueGetter: (value, row) => row.purchaseTypeCd?.value,
      },
      {
        field: 'useYn',
        headerName: '사용상태',
        headerAlign: 'center',
        align: 'center',
        maxWidth: 100,
        valueGetter: (value, row) => row.useStatusCd?.value,
      },
      {
        field: 'userNm',
        headerName: '사용자',
        headerAlign: 'center',
        align: 'center',
        maxWidth: 100,
        valueGetter: (value, row) => row.useUser?.userNm,
      },
      {
        field: 'useStartDt',
        headerName: '사용시작월',
        headerAlign: 'center',
        align: 'center',
        maxWidth: 100,
        valueGetter: (value, row) =>
          row.useStartDt ? dayjs(row.useStartDt).format('YYYY/MM') : '-',
      },
      {
        field: 'memo',
        headerName: '비고',
        headerAlign: 'center',
        flex: 1,
      },
      {
        field: 'regDt',
        headerName: '등록일',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => dayjs(row.regDt).format('YYYY-MM-DD'),
      },
      {
        field: 'regUser',
        headerName: '등록자',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => row.regUser.userNm,
      },
    ];
  }
  function getServerManagementGridCol(
    imgClickHandler: any,
  ): GridColDef<IServerManagementGridRow>[] {
    return [
      { field: 'id' },
      // {
      //   field: 'serverSid',
      //   headerName: 'server sid',
      //   headerAlign: 'center',
      //   maxWidth: 100,
      //   align: 'center',
      // },
      {
        field: 'inventoryNo',
        headerName: '자산관리번호',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
      },
      {
        field: 'name',
        headerName: '관리명',
        headerAlign: 'center',
        minWidth: 150,
      },
      {
        field: 'hostName',
        headerName: '호스트명',
        headerAlign: 'center',
        minWidth: 150,
      },
      {
        field: 'loc',
        headerName: '국사',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
      },
      {
        field: 'purposeCd',
        headerName: '용도',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row: any) => row.purposeCd.value,
        minWidth: 150,
      },
      {
        field: 'mainternenceMng',
        headerName: '운영담당자',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
      },
      {
        field: 'forwardIp',
        headerName: 'Forwarding IP',
        headerAlign: 'center',
        flex: 1,
      },
      {
        field: 'img',
        headerName: '사진',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: any) => {
          return params.row.serverFiles && params.row.serverFiles.length > 0 ? (
            <IconButton
              sx={{ mt: 1 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const slides: any[] = [];
                params.row.serverFiles.forEach((e: any) => {
                  slides.push({
                    src: 'https://works-api.surfinn.kr/common/upload' + e.filePath,
                  });
                });
                imgClickHandler(slides);
              }}
            >
              <Iconify icon="ph:image" />
            </IconButton>
          ) : (
            <>-</>
          );
        },
      },
      {
        field: 'regDt',
        headerName: '등록일',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => dayjs(row.regDt).format('YYYY-MM-DD'),
      },
      {
        field: 'regUser',
        headerName: '등록자',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => row.regUser.userNm,
      },
    ];
  }
  function getVmManagementGridCol(departments?: IDepartment[]): GridColDef<IVmManagementGridRow>[] {
    return [
      { field: 'id' },
      {
        field: 'vmSid',
        headerName: 'VM sid',
        headerAlign: 'center',
        maxWidth: 100,
        align: 'center',
      },
      {
        field: 'vmName',
        headerName: 'VM Name',
        headerAlign: 'center',
        minWidth: 200,
      },
      {
        field: 'vmIp',
        headerName: 'VM IP',
        headerAlign: 'center',
        minWidth: 200,
      },
      {
        field: 'useYn',
        headerName: '사용여부',
        headerAlign: 'center',
        valueGetter: (value, row) => row.useStatusCd?.value,
        maxWidth: 80,
      },
      {
        field: 'mngDeptSid',
        headerName: '관리부서',
        headerAlign: 'center',
        minWidth: 150,
        valueGetter: (value, row) => {
          if (departments && departments.length > 0) {
            const dept = departments.find((dept) => dept.deptSid === row.mngDeptSid);
            return dept?.deptNm || '';
          }
          return '';
        },
      },
      {
        field: 'memo',
        headerName: '내용',
        headerAlign: 'center',
        flex: 1,
      },
      {
        field: 'url',
        headerName: 'URL',
        headerAlign: 'center',
        flex: 1,
      },
      {
        field: 'regDt',
        headerName: '등록일',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => dayjs(row.regDt).format('YYYY-MM-DD'),
      },
      {
        field: 'regUser',
        headerName: '등록자',
        headerAlign: 'center',
        align: 'center',
        valueGetter: (value, row) => row.regUser.userNm,
      },
    ];
  }
  function getChatbotFAQGridCol(): GridColDef<ChatbotFAQ>[] {
    return [
      { field: 'id', valueGetter: (value, row) => row.faq_id },
      {
        field: 'faq_id',
        headerName: '번호',
        headerAlign: 'center',
        align: 'center',
        width: 90,
        valueGetter: (value, row) => row.faq_id,
      },
      {
        field: 'question',
        headerName: '질문',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.question,
      },
      {
        field: 'tag',
        headerName: '태그',
        headerAlign: 'center',
        flex: 1,
        renderCell: ({ row }) => (
          <ChipContainer>
            {row.tag
              ? row.tag
                  .split(',')
                  .map((t: string, index) => (
                    <StyledChip key={index} label={t.trim()} sx={{ margin: '3px' }} />
                  ))
              : ''}
          </ChipContainer>
        ),
      },
      {
        field: 'reg_user_id',
        headerName: '작성자',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.reg_user_id,
      },
      {
        field: 'reg_dt',
        headerName: '작성일',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => dayjs(row.reg_dt).format('YYYY-MM-DD'),
      },
      {
        field: 'upd_user_id',
        headerName: '편집자',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.upd_user_id,
      },
      {
        field: 'upd_dt',
        headerName: '편집일',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => dayjs(row.upd_dt).format('YYYY-MM-DD'),
      },
      {
        field: 'use_yn',
        headerName: '노출 여부',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => (row.use_yn === true ? '⭕️' : '❌'),
      },
    ];
  }

  function getChatbotFeedbackGridCol(): GridColDef<ChatbotFeedback>[] {
    return [
      { field: 'id', valueGetter: (value, row) => row.feedback_id },
      {
        field: 'feedback_id',
        headerName: '번호',
        headerAlign: 'center',
        align: 'center',
        width: 90,
        valueGetter: (value, row) => row.feedback_id,
      },
      {
        field: 'content',
        headerName: '제목',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.title,
      },
      {
        field: 'reg_user_id',
        headerName: '작성자',
        headerAlign: 'center',
        align: 'center',
        width: 90,
        valueGetter: (value, row) => row.reg_user_id,
      },
      {
        field: 'reg_dt',
        headerName: '작성일',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        valueGetter: (value, row) => dayjs(row.reg_dt).format('YYYY-MM-DD'),
      },
      {
        field: 'upd_dt',
        headerName: '편집일',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        valueGetter: (value, row) => dayjs(row.upd_dt).format('YYYY-MM-DD'),
      },
      {
        field: 'apply_yn',
        headerName: '피드백 처리 여부',
        headerAlign: 'center',
        align: 'center',
        width: 120,
        valueGetter: (value, row) => (row.apply_yn === true ? '⭕️' : '❌'),
      },
    ];
  }

  function displayIconByStatus(status: 'Success' | 'Failed' | string) {
    if (status === 'Success') {
      return '✅';
    } else if (status === 'Failed') {
      return '❌';
    } else if (status === 'Loading') {
      return (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <Iconify icon="line-md:loading-loop" />
        </Box>
      );
    } else {
      return '---';
    }
  }
  function getVectorDBGridCold(): GridColDef<VectorDB>[] {
    return [
      { field: 'id', valueGetter: (value, row) => row.vdb_id },
      {
        field: 'vdb_id',
        headerName: '번호',
        headerAlign: 'center',
        align: 'center',
        width: 90,
        valueGetter: (value, row) => row.vdb_id,
      },
      {
        field: 'vdb_type',
        headerName: '벡터 데이터베이스 종류',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.vdb_type,
      },
      {
        field: 'vdb_path',
        headerName: '데이터베이스 경로',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.vdb_path,
      },
      {
        field: 'collection_name',
        headerName: '컬렉션 이름',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.collection_name,
      },
    ];
  }
  function getNasSyncGridCol({
    onLoadNas,
  }: {
    onLoadNas: (nas_id: number) => void;
  }): GridColDef<NasList>[] {
    return [
      { field: 'id', valueGetter: (value, row) => row.nas_id },
      {
        field: 'nas_id',
        headerName: '번호',
        headerAlign: 'center',
        align: 'center',
        width: 90,
        valueGetter: (value, row) => row.nas_id,
      },
      {
        field: 'nas_path',
        headerName: 'NAS 경로',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.nas_path,
      },
      {
        field: 'nas_dir_name',
        headerName: '연동 폴더 이름',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.nas_dir_name,
      },
      {
        field: 'nas_status',
        headerName: '적재 상태',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        renderCell: (params) => displayIconByStatus(params.row.nas_status),
      },
      {
        field: 'nas_last_loading',
        headerName: '최근 적재',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) =>
          row.nas_last_loading ? dayjs(row.nas_last_loading).format('YYYY/MM/DD') : '---',
      },
      {
        field: 'nas_last_failure',
        headerName: '최근 실패',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) =>
          row.nas_last_failure ? dayjs(row.nas_last_failure).format('YYYY/MM/DD') : '---',
      },
      {
        field: 'rdb_load',
        headerName: '적재',
        headerAlign: 'center',
        align: 'center',
        width: 90,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              onLoadNas(params.row.nas_id);
            }}
          >
            실행
          </Button>
        ),
      },
    ];
  }
  function getRdbSyncGridCol({
    onLoadRdb,
  }: {
    onLoadRdb: (rdb_id: number) => void;
  }): GridColDef<RDBList>[] {
    return [
      { field: 'id', valueGetter: (value, row) => row.rdb_id },
      {
        field: 'rdb_no',
        headerName: '번호',
        headerAlign: 'center',
        align: 'center',
        width: 90,
        valueGetter: (value, row) => row.rdb_id,
      },
      {
        field: 'rdb_path',
        headerName: '데이터베이스 경로',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.rdb_path,
      },
      {
        field: 'rdb_name',
        headerName: '데이터베이스 이름',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.rdb_name,
      },
      {
        field: 'rdb_status',
        headerName: '적재 상태',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.rdb_status,
        renderCell: (params) => displayIconByStatus(params.row.rdb_status),
      },
      {
        field: 'rdb_last_loading',
        headerName: '최근 적재',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) =>
          row.rdb_last_loading ? dayjs(row.rdb_last_loading).format('YYYY/MM/DD') : '---',
      },
      {
        field: 'rdb_last_failure',
        headerName: '최근 실패',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) =>
          row.rdb_last_failure ? dayjs(row.rdb_last_failure).format('YYYY/MM/DD') : '---',
      },
      {
        field: 'rdb_load',
        headerName: '적재',
        headerAlign: 'center',
        align: 'center',
        width: 90,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              onLoadRdb(params.row.rdb_id);
            }}
          >
            실행
          </Button>
        ),
      },
    ];
  }

  function getWebPageListGridCol(): GridColDef<{ web_page_name: string; web_page_path: string }>[] {
    return [
      { field: 'id', valueGetter: (value, row) => row.web_page_path },
      {
        field: 'web_page_name',
        headerName: '웹 페이지 이름',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        editable: true,
        valueGetter: (value, row) => row.web_page_name,
      },
      {
        field: 'web_page_path',
        headerName: '웹 페이지 경로',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        editable: true,
        valueGetter: (value, row) => row.web_page_path,
      },
    ];
  }

  function getWebCrawlingSyncGridCol({
    onLoadWebCrawling,
  }: {
    onLoadWebCrawling: (web_id: number) => void;
  }): GridColDef<WebCrawlingList>[] {
    return [
      { field: 'id', valueGetter: (value, row) => row.web_id },
      {
        field: 'web_id',
        headerName: '번호',
        headerAlign: 'center',
        align: 'center',
        width: 90,
        valueGetter: (value, row) => row.web_id,
      },
      {
        field: 'web_service_name',
        headerName: '서비스 이름',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.web_service_name,
      },
      {
        field: 'web_num_page',
        headerName: '웹 페이지 수',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.web_page_list.length,
      },
      {
        field: 'web_status',
        headerName: '적재 상태',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) => row.web_status,
        renderCell: (params) => displayIconByStatus(params.row.web_status),
      },
      {
        field: 'web_last_loading',
        headerName: '최근 적재',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) =>
          row.web_last_loading ? dayjs(row.web_last_loading).format('YYYY/MM/DD') : '---',
      },
      {
        field: 'web_last_failure',
        headerName: '최근 실패',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: (value, row) =>
          row.web_last_failure ? dayjs(row.web_last_failure).format('YYYY/MM/DD') : '---',
      },
      {
        field: 'web_load',
        headerName: '적재',
        headerAlign: 'center',
        align: 'center',
        width: 90,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              onLoadWebCrawling(params.row.web_id);
            }}
          >
            실행
          </Button>
        ),
      },
    ];
  }
  return {
    getEmployeeManagementGridCol,
    getDeptManagementGridCol,
    getGroupManagementGridCol,
    getClientManagementGridCol,
    getReportTmpltGridCol,
    getReportGridCol,
    getReportAttachmentGridCol,
    getOrganizationLayoutEmployeeGridCol,
    getProjectManagementGridCol,
    getEquipmentManagementGridCol,
    getServerManagementGridCol,
    getVmManagementGridCol,
    getWorkReportGridCol,
    getChatbotFAQGridCol,
    getChatbotFeedbackGridCol,
    getVectorDBGridCold,
    getNasSyncGridCol,
    getRdbSyncGridCol,
    getWebPageListGridCol,
    getWebCrawlingSyncGridCol,
  };
}

export default useGetGridCol;

const ChipContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  overflowX: 'auto',
  overflowY: 'hidden',
  boxSizing: 'border-box',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 24,
}));
