import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { Search } from 'lucide-react';
import { useStores } from 'src/models';

import { useChatActionsContext } from '../../context/ChatContext';

interface SearchModalProps {
  open: boolean;
  onClose: () => void;
}

const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
const MAX_CHARS = 300;

const SearchModal: React.FC<SearchModalProps> = ({ open, onClose }) => {
  const { authStore } = useStores();
  const { sendMessageToAI, setIsChatWindowOpened, setConversations } = useChatActionsContext();
  const [isComposing, setIsComposing] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const userSid = authStore.user?.userSid;

  const prepareForNewChat = () => {
    if (userSid) {
      setIsChatWindowOpened(true);
      setConversations([]);
      sendMessageToAI(searchQuery, userSid);
    }
  };
  const resetToCloseModalSituation = () => {
    setSearchQuery('');
    onClose();
  };

  // 키보드 엔터 누를 때 메시지 전송하는 함수
  const handleEnterKeyPress = (event: React.KeyboardEvent) => {
    /**
     * event.key === 'Enter' && !event.shiftKey 의미
     * 동시에 엔터키를 눌렀고 쉬프트 키를 안 눌렀으면 메시지 전송,
     * 동시에 엔터키를 눌렀고 쉬프트 키를 눌렀으면 줄바꿈 (TextField의 multiline 옵션).
     */
    const shouldSendMessage = event.key === 'Enter' && !event.shiftKey;
    if (!isComposing && shouldSendMessage) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    prepareForNewChat();
    resetToCloseModalSituation();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSearchQuery('');
        onClose();
      }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: { borderRadius: 2 },
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.7)', // 더 진한 dim 효과
          backdropFilter: 'blur(4px)', // 블러 효과 추가
        },
      }}
      // 모달이 천천히 나타나도록 트랜지션 추가
      TransitionProps={{
        timeout: {
          enter: 800,
          exit: 200,
        },
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            fullWidth
            multiline
            variant="outlined"
            placeholder="무엇이든 검색하기..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            inputProps={{ maxLength: MAX_CHARS }}
            onKeyDown={(event) => handleEnterKeyPress(event)}
            onCompositionStart={() => setIsComposing(true)}
            onCompositionEnd={() => setIsComposing(false)}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiOutlinedInput-root': {
                p: 2,
                border: '2px solid #0b4d8f',
              },
              '& .MuiInputBase-input': {
                p: 1.5,
              },
              border: '13px solid #3280CE',
              borderRadius: '5px',
            }}
            InputProps={{
              startAdornment: (
                <Box sx={{ mr: 1, display: 'flex' }}>
                  <Search size={20} color="gray" />
                </Box>
              ),
              endAdornment: (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: '5px' }}
                    disabled={searchQuery.length === 0 || searchQuery.length > MAX_CHARS}
                    onClick={handleSubmit}
                  >
                    <SendIcon sx={{ fontSize: 20 }} />
                  </Button>
                  <Typography
                    variant="caption"
                    sx={{ mt: 1, display: 'block', textAlign: 'center' }}
                  >
                    {searchQuery.length} / {MAX_CHARS}
                  </Typography>
                </Box>
              ),
            }}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
};

const SearchInput: React.FC = () => {
  const { setCurrentChatRoomId } = useChatActionsContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const searchEvent = () => {
    setIsModalOpen(true);
    setCurrentChatRoomId('');
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((isMac ? e.metaKey : e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        searchEvent();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <>
      <CustomTooltip
        title={`새로운 채팅 시작하려면 (${isMac ? '⌘' : 'Ctrl'}+K)를 눌러주세요`}
        arrow
        placement="bottom"
      >
        <ButtonBase
          onClick={() => {
            searchEvent();
          }}
          sx={{
            width: '240px',
            alignSelf: 'center',
            maxWidth: 800,
            textAlign: 'left',
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'grey.200',
            bgcolor: 'grey.50',
            '&:hover': {
              bgcolor: 'grey.100',
            },
            transition: 'background-color 0.2s',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              width: '100%',
              p: 1.5,
            }}
          >
            <Icon icon={'tabler:message-chatbot'} color={'#3280CE'} fontSize={24} />
            <Typography
              sx={{
                flexGrow: 1,
                color: 'text.secondary',
              }}
            >
              새로운 채팅하기...
            </Typography>
          </Box>
        </ButtonBase>
      </CustomTooltip>
      <SearchModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

// 커스텀 툴팁 스타일
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    fontSize: 12,
    padding: '8px 12px',
    borderRadius: 6,
    marginTop: '4px !important',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[800],
  },
}));
export default SearchInput;
