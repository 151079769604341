import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { AIResponse, ChatList, ChatListRequest } from 'src/@types/apiResponseTypes';

import { AI_RESPONSE_DEFAULT_VALUE, ConversationType, TSetState } from '../utils/types';

import useChatResponse from './useChatResponse';

export interface ChatState {
  conversations: ConversationType[] | undefined;
  isChatWindowOpened: boolean;
  isInitialChatPage: boolean;
  currentChatRoomId: string;
  aiResponse: AIResponse;
  aiResponseStatus: {
    isAITyping: boolean;
    error: string | null;
  };
  isStreaming: boolean;
  sidebarRef: React.RefObject<SidebarRef>;
}

export interface ChatActions {
  setIsChatWindowOpened: (isOpened: boolean) => void;
  setCurrentChatRoomId: (id: string) => void;
  setAiResponse: Dispatch<SetStateAction<AIResponse>>;
  setAiResponseStatus: Dispatch<
    SetStateAction<{
      isAITyping: boolean;
      error: string | null;
    }>
  >;
  setConversations: Dispatch<SetStateAction<ConversationType[] | undefined>>;
  setIsStreaming: Dispatch<SetStateAction<boolean>>;
  findChatHistory: ({ chat_id }: { chat_id: string }) => Promise<void>;
  getChatList: (chatListRequest: ChatListRequest) => Promise<ChatList[]>;
  sanitizeChatRoomState: () => void;
  setIsInitialChatPage: TSetState<boolean>;
  moveToInitialChatPage: () => void;
}

interface SidebarRef {
  chatListState: {
    chatList: ChatList[];
    setChatList: React.Dispatch<React.SetStateAction<ChatList[]>>;
  };
}

export const useChatState = (
  initialAiResponse: AIResponse = AI_RESPONSE_DEFAULT_VALUE,
): ChatState & ChatActions => {
  const { history, conv, getChatList } = useChatResponse();

  const [isChatWindowOpened, setIsChatWindowOpened] = useState(false);
  const [currentChatRoomId, setCurrentChatRoomId] = useState<string>('');
  const [aiResponse, setAiResponse] = useState<AIResponse>(initialAiResponse);
  const [aiResponseStatus, setAiResponseStatus] = useState<{
    isAITyping: boolean;
    error: string | null;
  }>({ isAITyping: false, error: null });
  const [isStreaming, setIsStreaming] = useState(false);
  const [isInitialChatPage, setIsInitialChatPage] = useState(true);

  const sidebarRef = useRef<SidebarRef>(null);

  const { conversations, setConversations } = conv;
  const { findChatHistory } = history;

  const sanitizeChatRoomState = () => {
    setCurrentChatRoomId('');
    setConversations([]);
  };

  const moveToInitialChatPage = () => {
    sanitizeChatRoomState();
    setIsInitialChatPage(true);
  };

  return {
    // State
    conversations,
    isChatWindowOpened,
    isInitialChatPage,
    currentChatRoomId,
    aiResponse,
    aiResponseStatus,
    isStreaming,
    sidebarRef,

    // Actions
    setAiResponse,
    setAiResponseStatus,
    setConversations,
    setCurrentChatRoomId,
    setIsChatWindowOpened,
    setIsStreaming,
    findChatHistory,
    getChatList,
    sanitizeChatRoomState,
    setIsInitialChatPage,
    moveToInitialChatPage,
  };
};
