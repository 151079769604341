import { Chip, styled } from '@mui/material';
import { ChatbotFAQ } from 'src/@types/apiResponseTypes';

function FaqTagRecommender({
  chatFaqs,
  onSendMessageToAI,
}: {
  chatFaqs: ChatbotFAQ[];
  onSendMessageToAI: (response: string) => void;
}) {
  return (
    <>
      {chatFaqs.length > 0 &&
        chatFaqs.map(({ tag, faq_id, question }) => (
          <StyledChip
            key={faq_id}
            label={tag}
            variant="outlined"
            onClick={() => {
              onSendMessageToAI(question);
            }}
          />
        ))}
    </>
  );
}

const StyledChip = styled(Chip)(() => ({
  borderRadius: '20px',
  padding: '8px 12px',
  height: '36px',
  margin: '16px 4px',
  backgroundColor: 'transparent',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  color: '#676767',
  fontSize: '13px',
  boxShadow: '',
  '& .MuiChip-icon': {
    color: 'inherit',
    marginLeft: '8px',
  },
  '& .MuiChip-label': {
    padding: '0 8px',
    fontSize: '14px',
    fontWeight: 400,
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
  },
  '&.selected': {
    backgroundColor: '#F7F7F8',
    borderColor: '#10A37F',
    color: '#10A37F',
    '& .MuiChip-icon': {
      color: '#10A37F',
    },
  },
  transition: 'all 0.2s ease-in-out',
}));

export default FaqTagRecommender;
