import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Typography } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

interface NewChatButtonProps extends ButtonProps {}

function NewChatButton({ ...buttonProps }: NewChatButtonProps) {
  return (
    <Button
      sx={{
        height: '40px',
        border: '1px solid #FFF',
        borderRadius: '20px',
        pr: 3,
      }}
      component="label"
      variant="contained"
      fullWidth
      {...buttonProps}
    >
      <Typography variant="h6">
        <CommentOutlinedIcon sx={{ fontSize: 18 }} /> New Chat
      </Typography>
    </Button>
  );
}

export default NewChatButton;
